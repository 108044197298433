import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { HeroImage, Intro, Category, Pagination, Section } from "@components/Work"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Arrow } from "@components/UI"
import { splitText } from "@utils"
import LogoOne from "@svg/work/lumendi/logo-01.svg"
import LogoTwo from "@svg/work/lumendi/logo-02.svg"
import workData from "@json/work.json"

const LumendiPage = ({ data }) => {
  const {
    logoThree,
    printOneDesktopImage,
    printOneTabletImage,
    printTwoDesktopImage,
    printTwoTabletImage,
    webpageOneImage,
    webpageTwoImage,
    webpageThreeImage,
    printAdditionalOneImage,
    printAdditionalTwoImage,
  } = data
  const { name, headline, theme } = workData["lumendi"]
  const { themeColor } = usePageSetup({
    themeColor: theme.background,
    navigationColor: theme.navigationColor,
    displayGeneralForm: false,
  })

  return (
    <Layout>
      <Seo title={name} description={headline} />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1 className="tracking-normal text-white text-18">{name}</h1>
            <h2
              data-aos="stagger"
              className="text-work-headline mt-clamp-6-8"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": headline,
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-white" colorBottom="bg-white" fillColor="text-white" />

      <main className="overflow-hidden">
        <HeroImage client="lumendi" />
        <Category>B2B branding</Category>
        <Intro>
          <Intro.Overview>
            <Intro.Title>Building a game-changing B2B brand for two trailblazers.</Intro.Title>
            <div className="mt-10">
              <p>
                When we met the founders of Lumendi, they were a startup with a noble mission—to transform
                gastrointestinal (GI) surgery with their groundbreaking technology.
              </p>
              <p>
                But to become a leader in the highly competitive global medical device market, they needed a compelling
                story and a standout B2B identity that would differentiate them from their large, well-known
                competitors.
              </p>
              <p>
                JK’s approach was just what they needed—and it’s how we began a long partnership, one that has seen
                Lumendi go from a startup trying to start conversations in a crowded market, to a mature brand with
                multiple industry-leading products. JK has been with them every step of the way, helping Lumendi to
                tell—and evolve—a standout brand story that has established them as a technology leader in the field of
                GI surgery.
              </p>
            </div>
          </Intro.Overview>
          <Intro.Deliverables>
            <li>Branding</li>
            <li>Messaging</li>
            <li>Product naming</li>
            <li>Visual identity</li>
            <li>Logo architecture</li>
            <li>Copywriting</li>
            <li>Sales &amp; marketing collateral</li>
            <li>Brand guidelines</li>
          </Intro.Deliverables>
        </Intro>

        <section className="mt-clamp-34-29">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Title>Creating a strong platform and a powerful voice, together.</Section.Title>
            <Section.Description>
              <p>
                To be successful in their initial market launch, Lumendi had to gain brand awareness, capture the
                attention of hospital decision makers, and—most importantly—convince GI physicians to adopt a new
                technology and technique.
              </p>
              <p>
                So we began by taking a close look at the perceptions of key opinion leaders in their space, uncovering
                insights that would help us convey Lumendi’s value in the most meaningful way possible.
              </p>
            </Section.Description>
          </Section>
          <div className="mt-clamp-17-22">
            <div className="container relative flex">
              <CursorMarker>
                <CursorMarker.Gallery />
              </CursorMarker>
              <div className="w-full sm:w-5/6 lg:w-1/2">
                <Carousel>
                  <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                    <Carousel.Slide>
                      <div data-aos="fade-up" className="w-full pointer-events-none">
                        <LogoOne />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="200" className="w-full pointer-events-none">
                        <LogoTwo />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                        <GatsbyImage image={getImage(logoThree)} alt="Logo 3" />
                      </div>
                    </Carousel.Slide>
                  </Carousel.Swiper>
                </Carousel>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-25-41">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Description>
              <p>
                Then it was time for a day of collaborative brand-building with Lumendi’s leaders, which resulted in a
                persuasive position and tagline—“Healthcare, elevated”—for both the company and its innovative new
                device, dubbed “DiLumen” by our naming team.
              </p>
              <p>
                We also developed differentiated messaging tailored to their target audiences, and a naming and logo
                system to ensure harmony among future products.
              </p>
            </Section.Description>
          </Section>
          <div className="container flex justify-center pt-clamp-26-35">
            <div className="w-full xl:w-10/12">
              <div className="w-full">
                <h3 data-aos="fade-up" className="text-18">
                  Colors
                </h3>
              </div>
              <div data-aos="fade-up" className="mt-16">
                <div className="grid w-full grid-cols-1 text-white sm:grid-cols-2">
                  <div className="pb-5 pl-5 pt-39" style={{ background: "#5C068C" }}>
                    <span className="font-bold text-18">#5C068C</span>
                  </div>
                  <div className="pb-5 pl-5 text-white pt-39" style={{ background: "#1D252D" }}>
                    <span className="font-bold text-18">#1D252D</span>
                  </div>
                </div>
                <div className="grid w-full grid-cols-2 text-white lg:grid-cols-6">
                  <div className="pb-5 pl-5 pt-14" style={{ background: "#3C1053" }}>
                    <span className="font-bold text-18">#3C1053</span>
                  </div>
                  <div className="pb-5 pl-5 pt-14" style={{ background: "#5B6770" }}>
                    <span className="font-bold text-18">#5B6770</span>
                  </div>
                  <div className="pb-5 pl-5 pt-14" style={{ background: "#FFB81C" }}>
                    <span className="font-bold text-18">#FFB81C</span>
                  </div>
                  <div className="pb-5 pl-5 pt-14" style={{ background: "#E35205" }}>
                    <span className="font-bold text-18">#E35205</span>
                  </div>
                  <div className="pb-5 pl-5 pt-14" style={{ background: "#78D64B" }}>
                    <span className="font-bold text-18">#78D64B</span>
                  </div>
                  <div className="pb-5 pl-5 pt-14" style={{ background: "#D1CCBD" }}>
                    <span className="font-bold text-18">#41B6E6</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-37-64">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Title>A brand evolution designed to attract acquisition partners.</Section.Title>
            <Section.Description>
              <p>
                After three years in the market and over 1,000 clinical cases completed, Lumendi gave us a new
                challenge—evolve their brand story to help attract and inspire investors and potential acquirers.
              </p>
              <p>
                So we did just that, updating their brand position to reflect their continued innovation and forward
                momentum and also created an investor-targeted messaging framework that telegraphed Lumendi’s focus on
                raising the bar in gastrointestinal care.
              </p>
            </Section.Description>
          </Section>
          <div className="container relative mt-clamp-18-25">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Print />
              </CursorMarker>
              <Carousel.Swiper
                breakpoints={{
                  1436: {
                    spaceBetween: 80,
                    slidesPerView: "auto",
                  },
                }}
              >
                <Carousel.Slide>
                  <div data-aos="fade-up">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneTabletImage)}
                          alt="Print 1"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneDesktopImage)}
                          alt="Print 1"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="200">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoTabletImage)}
                          alt="Print 2"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoDesktopImage)}
                          alt="Print 2"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
              </Carousel.Swiper>
            </Carousel>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-50-79">
          <Section className="col-span-10">
            <Section.Title>What’s in a product name? Powerful connections to new customers.</Section.Title>
            <Section.Description>
              <p>
                The next step in our partnership was supporting two new product launches. To make it a success, Lumendi
                needed two compelling product stories, along with names for one of the new devices and two new
                proprietary features.
              </p>
              <p>
                JK’s name development process resulted in distinctive and memorable names that convey the value and
                benefits of each offering while fitting seamlessly within the established naming system and overall
                brand platform.
              </p>
            </Section.Description>
          </Section>

          <div className={`${themeColor} mt-24`}>
            <div className="container relative">
              <div className="absolute inset-0">
                <div className="w-full aspect-w-326 aspect-h-93 sm:aspect-w-1436 sm:aspect-h-189 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div className="absolute inset-0 flex">
                <div className="w-full mt-auto aspect-w-326 aspect-h-144 sm:aspect-w-1436 sm:aspect-h-351 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div>
                <div className="overflow-hidden shadow rounded-3xl">
                  <GatsbyImage
                    className="w-full h-full"
                    objectFit="contain"
                    image={getImage(webpageOneImage)}
                    alt="Webpage - 1"
                  />
                </div>
              </div>
              <div className="grid-cols-2 sm:grid gap-x-4 mt-clamp-16-37">
                <div>
                  <div className="overflow-hidden shadow rounded-3xl">
                    <GatsbyImage
                      className="w-full h-full"
                      objectFit="contain"
                      image={getImage(webpageTwoImage)}
                      alt="Webpage - 2"
                    />
                  </div>
                </div>
                <div className="flex items-center mt-clamp-16-37 sm:mt-0">
                  <div className="overflow-hidden shadow rounded-3xl">
                    <GatsbyImage
                      className="w-full h-full"
                      objectFit="contain"
                      image={getImage(webpageThreeImage)}
                      alt="Webpage - 3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-37-56 pb-clamp-35-71">
          <div className="container flex justify-center">
            <div className="md:w-10/12">
              <div className="grid-cols-10 lg:grid">
                <div className="col-span-8 pr-4">
                  <Section.Title>Continuing to raise the bar for a brand on the move.</Section.Title>
                </div>
              </div>
              <Section.Description>
                <p>
                  As Lumendi continues to move into new global markets and approach new acquisition partners, JK
                  continues to help them adapt and grow. It’s been a journey full of partnership and pivots, obstacles
                  and opportunities, and we’ve enjoyed it every step of the way.
                </p>
              </Section.Description>
            </div>
          </div>
          <div className={`${themeColor} mt-clamp-16-30`}>
            <div className="container relative">
              <div className="absolute inset-0">
                <div className="w-full aspect-w-326 aspect-h-100 sm:aspect-w-1436 sm:aspect-h-270 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div className="absolute inset-0 flex">
                <div className="w-full mt-auto aspect-w-326 aspect-h-80 sm:aspect-w-1436 sm:aspect-h-158 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div className="grid-cols-6 grid-rows-1 sm:grid lg:grid-cols-12 gap-x-4">
                <div className="col-start-1 col-end-5 row-start-1 row-end-2 lg:col-span-7 sm:pt-80 lg:pt-32">
                  <GatsbyImage
                    className="w-full h-full"
                    objectFit="contain"
                    image={getImage(printAdditionalOneImage)}
                    alt="Additional Print - 1"
                  />
                </div>
                <div className="flex col-start-4 col-end-7 row-start-1 row-end-2 mt-20 lg:col-span-5 sm:mt-0">
                  <div className="mb-auto">
                    <GatsbyImage
                      className="w-full h-full"
                      objectFit="contain"
                      image={getImage(printAdditionalTwoImage)}
                      alt="Additional Print - 2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Pagination client="lumendi" />
      </main>
    </Layout>
  )
}

export default LumendiPage

export const lumendiQuery = graphql`
  query LumendiPageQuery {
    logoThree: file(relativePath: { eq: "work/lumendi/logo-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneDesktopImage: file(relativePath: { eq: "work/lumendi/print-01-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneTabletImage: file(relativePath: { eq: "work/lumendi/print-01-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoDesktopImage: file(relativePath: { eq: "work/lumendi/print-02-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoTabletImage: file(relativePath: { eq: "work/lumendi/print-02-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageOneImage: file(relativePath: { eq: "work/lumendi/webpage-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageTwoImage: file(relativePath: { eq: "work/lumendi/webpage-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageThreeImage: file(relativePath: { eq: "work/lumendi/webpage-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printAdditionalOneImage: file(relativePath: { eq: "work/lumendi/print-additional-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printAdditionalTwoImage: file(relativePath: { eq: "work/lumendi/print-additional-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
